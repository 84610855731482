import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import Header from "../../components/Header"
import Card from "../../components/Card"
import { smallContainer } from "../../components/container"
import WhatToDoNav from "../../components/WhatToDoNav"
import {
  ExternalLinkList,
  ExternalLink,
} from "../../components/ExternalLinkList"
import DrinkImage from "../../components/DrinkImage"

// Hero Section
const StyledCard = styled(Card)`
  margin: 50px auto;
`

const CardContent = styled.div`
  position: relative;
  z-index: 100;
`

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
  > span {
    display: inline-block;
    font-size: 21px;
    margin-left: 10px;
  }
`

const drinkImage = css`
  position: absolute;
  z-index: 11;
  bottom: -110px;
  right: -70px;
  width: 180px;
  mix-blend-mode: multiply;

  @media (min-width: 480px) {
    width: 250px;
    right: -90px;
  }

  @media (min-width: 680px) {
    width: 300px;
    right: -100px;
    bottom: -120px;
  }

  @media (min-width: 860px) {
    width: 360px;
    right: -120px;
    bottom: -140px;
  }
`
const FoodAndDrinks = () => (
  <Layout>
    <SEO title="Food + Drinks" />
    <Header />
    <div css={smallContainer}>
      <StyledCard>
        <CardContent>
          <h1 css={heroHeadline}>
            Food + Drinks <span>(and a Casino)</span>
          </h1>
          <ExternalLinkList>
            <ExternalLink label="Bar Cecil" href="https://barcecil.com/" />
            <ExternalLink
              label="Birba Restaurant"
              href="https://birbaps.com/"
            />
            <ExternalLink
              label="Las Casuelas Terraza"
              href="https://www.lascasuelas.com/"
            />
            <ExternalLink
              label="Bootlegger&rsquo;s Tiki"
              href="https://www.bootleggertiki.com/"
            />
            <ExternalLink
              label="Melvyn&rsquo;s Restaurant + Lounge"
              href="https://www.inglesideinn.com/melvyns/"
            />
            <ExternalLink
              label="Boozehounds"
              href="https://boozehoundsps.com/"
            />
            <ExternalLink
              label="Sandfish by Engin Onural"
              href="https://sandfishps.com/"
            />
            <ExternalLink
              label="Rooster and the Pig"
              href="https://www.roosterandthepig.com/"
            />
            <ExternalLink
              label="Workshop Kitchen + Bar"
              href="https://www.workshoppalmsprings.com/"
            />
            <ExternalLink
              label="1501 Uptown"
              href="https://www.1501uptown.com/"
            />
            <ExternalLink
              label="Tropicale"
              href="https://apps.thetropicale.com/"
            />
            <ExternalLink
              label="Farm"
              href="https://www.farmpalmsprings.com/"
            />
            <ExternalLink
              label="The Parker (Norma&rsquo;s, Mister Parker, Counter Reformation)"
              href="https://www.parkerpalmsprings.com/food-and-drink"
            />
            <ExternalLink
              label="The Paul Bar"
              href="https://www.thepaulbarps.com/"
            />
            <ExternalLink
              label="The Colony Palms"
              href="https://colonypalmshotel.com/dine/"
            />
            <ExternalLink
              label="La Serena Villas (Azucar)"
              href="https://azucarpalmsprings.com/"
            />
            <ExternalLink label="Seymour's" href="https://seymoursps.com/" />
            <ExternalLink
              label="Del Rey"
              href="http://delreypalmsprings.com/"
            />
            <ExternalLink
              label="Agua Caliente Casino"
              href="https://sparesortcasino.com/"
            />
            <ExternalLink
              label="Morongo Casinos (20 minutes from Avalon Hotel)"
              href="https://www.morongocasinoresort.com/"
            />
          </ExternalLinkList>
          <WhatToDoNav
            css={css`
              margin-top: 80px;
            `}
          />
          <div css={drinkImage}>
            <DrinkImage />
          </div>
        </CardContent>
      </StyledCard>
    </div>
  </Layout>
)

export default FoodAndDrinks
